import React, { Component } from "react";
import indexRoutes from "./routes";
import { Switch, Route, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import withRoot from '../components/withRoot';
import { withStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {withFirebase} from 'react-redux-firebase';
import "../styles/css";
import Flags from "../components/Flags";
import FlagManager from "../utils/FlagManager";
import { ReactReduxContext } from "react-redux";

const styles = (theme) => ({
  root: {
    // minWidth: '100vh',
    // minHeight: '100vh',
    background: theme.palette.background.default,
  },
  center: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
  progress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  dopContent: {
    minWidth: 768,
    display: "flex",
    height: "100%",
    // overflow: "hidden",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
  },
  main: {
    flexDirection: "column",
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    minWidth: 0,
  },
});

export const AppContext = React.createContext();

class Index extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
  };

  static contextType = ReactReduxContext;

  state = {
    appLoading: true,
    isAuthenticated: false,
    admin: false,
    partner: false,
    partnerId: '',
    profile: null,
    accessLevel: null,
    developer: false,
    network: '',
  };

  componentWillReceiveProps(nextProps) {
    const {auth, firebase} = nextProps;

    if (auth.isLoaded) {
      let state = {appLoading: false};
      const flagManager = new FlagManager(this.context.store);

      if (!auth.isEmpty) {
        const user = firebase.auth().currentUser;
        user.getIdTokenResult().then(result => {
          if (result.claims.admin) {
            state.isAuthenticated = true;
            state.admin = result.claims.admin;
            state.accessLevel = result.claims.accessLevel;
            state.developer = result.claims.developer;
          } else if (result.claims.partner) {
            state.isAuthenticated = true;
            state.partner = true;
            state.partnerId = result.claims.partnerId;
            state.accessLevel = result.claims.accessLevel;
            state.network = result.claims.network;
          } else {
            state.isAuthenticated = false;
            flagManager.error("Authorization Failed", "You need to be an admin to access this application.");
            return;
          }

          this.setState(state);
        }).catch(err => {
          console.log(err); //notify
          state.isAuthenticated = false;
          this.notify("Authorization Failed", "Failed to complete authentication.");
          this.setState(state);
        });
      } else if (auth.isEmpty && this.state.isAuthenticated) {
        state.isAuthenticated = false;
        this.setState(state);
      } else {
        this.setState(state);
      }
    }
  }

  render() {
    const { classes, ...parentProps } = this.props;
    const augmentedProps = {
      ...parentProps,
      profile: this.state.isAuthenticated ? {
        email: parentProps.auth.email,
      }: null,
      flagManager: new FlagManager(this.context.store),
    };

    return (
      <AppContext.Provider value={{
        admin: this.state.admin,
        partner: this.state.partner,
        isSuperAdmin: this.state.accessLevel === 9,
        accessLevel: this.state.accessLevel,
        isDev: this.state.developer,
        domain: this.state.domain,
        profile: augmentedProps.profile,
        network: this.state.network,
        partnerId: this.state.partnerId,
      }}>
        <div id="app">
          <div className={classes.dopContent}>
            <div className={classes.main}>
              <Switch>
                {
                  indexRoutes.map((prop, key) => {
                    return <Route path={prop.path}
                      exact={prop.exact}
                      key={key}
                      render={prop.renderFunc ? prop.renderFunc.bind(this, {
                        ...augmentedProps,
                        isAuthenticated: this.state.isAuthenticated,
                        authLoading: this.state.appLoading,
                      }): (props) => (
                        <prop.component
                          isAuthenticated={this.state.isAuthenticated}
                          accessLevel={this.state.accessLevel}
                          {...props}
                          {...augmentedProps}
                        />
                      )}
                    />;
                  })
                }
              </Switch>
              <Flags />
            </div>
          </div>
        </div>
      </AppContext.Provider>
    );
  }
}

const mapStateToProps = ({firebase: {auth, profile}}) => ({
  auth,
  profile,
});

export default withRouter(connect(mapStateToProps)(withRoot(withStyles(styles)(withFirebase(Index)))));