import { firebaseReducer, getFirebase } from "react-redux-firebase";
import { applyMiddleware, compose, createStore } from "redux";
import { firestoreReducer, getFirestore } from "redux-firestore";
import thunk from "redux-thunk";
import asyncDispatch from "../middleware/asyncDispatch";
import configurePromise from "../middleware/promise";
import createReducers from "../reducers/create";
import reducerRegistry from "./registry";

export default function create(client, data) {
  const middleware = [
    thunk.withExtraArgument(getFirebase),
    thunk.withExtraArgument(getFirestore),
    configurePromise(client),
    asyncDispatch,
  ];
  const createStoreWithFirebase = compose(applyMiddleware(...middleware))(
    createStore
  );

  const _createReducers = (reducers) =>
    createReducers(
      {
        firebase: firebaseReducer,
        firestore: firestoreReducer,
        ...reducers,
      },
      data
    );

  const store = createStoreWithFirebase(
    _createReducers(reducerRegistry.getReducers()),
    data
  );
  reducerRegistry.setChangeListener((reducers) => {
    store.replaceReducer(_createReducers(reducers));
  });

  return store;
}
