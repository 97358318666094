import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/messaging";
import "firebase/storage";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { BrowserRouter as Router } from "react-router-dom";
import { createFirestoreInstance } from "redux-firestore";
import "typeface-roboto";
import config from "./firebase-config.json";
import App from "./pages";
import * as serviceWorker from "./serviceWorker";
import createStore from "./store/create";
import ApiClient from "./utils/ApiClient";
import { createAsyncClient } from "./utils/async";

const rootElement = document.querySelector("#root");
const BACKEND_URL = "https://www2.wi-flix.com/api";

// Initialize firebase instance
firebase.initializeApp(config);
firebase.firestore();

const client = new ApiClient({
  apiPath: BACKEND_URL,
});

// Create store with reducers and initial state
export const store = createStore(client, {});

const rrfConfig = {
  firebase,
  config: {
    userProfile: "users",
    attachAuthIsReady: true,
    updateProfileOnLogin: true,
    useFirestoreForProfile: true,
  },
  createFirestoreInstance,
};

// TODO: make store promise middleware use async client
const async = createAsyncClient(store);

window.onload = () => {
  if (rootElement) {
    if (process.env.NODE_ENV === "production") {
      // set up an error reporting tool
    }

    render(
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfConfig} dispatch={store.dispatch}>
          <Router>
            <App async={async} />
          </Router>
        </ReactReduxFirebaseProvider>
      </Provider>,
      rootElement
    );
  }
};

// TODO: change this later to support service workers
serviceWorker.unregister();
