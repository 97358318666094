import React from "react";
import ReactDOM from "react-dom";
import q from "q";
import Prompt from "../components/CustomModal/Prompt";
import Modal from "../components/CustomModal/Modal";
import FlagManager from "../utils/FlagManager";

export const isObject = (object) => {
  return (
    object !== null &&
    typeof object === "object" &&
    object.constructor === {}.constructor
  );
};

export const parseResponse = (response, callback, store) => {
  const headers = response.headers;
  const headerContentType = headers.get("Content-Type") || "";
  const headerContentLength = headers.get("Content-Length") || 0;
  let contentType = headerContentType.toLowerCase();
  contentType = contentType ? contentType.replace("; charset=utf-8", "") : "";
  const unknownContentType = new Error("unknown response type");
  const responseOk = response.ok;

  const callCallback = (err, body) => {
    callback(err, body, response.status);
  };

  try {
    if (response.status === 403 && store) {
      const flagManager = new FlagManager(store);
      flagManager.error(
        "Unauthorized Access",
        "You do not have access to call this method." +
          "Contact your administrator for access to this method."
      );
    }

    if (response.ok && response.status === 204) {
      callCallback(null, null);
      return;
    }

    if (response.status === 404) {
      callCallback(new Error("not found"));
      return;
    }

    if (response.redirected) {
      callCallback(null, { redirect: true, location: response.url });
      return;
    }

    if (contentType) {
      if (contentType.toLowerCase() === "application/json") {
        response
          .json()
          .then((result) => {
            if (responseOk) {
              callCallback(null, result);
              return;
            }

            callCallback(result || new Error("error"));
          }, callCallback)
          .catch((err) => {
            console.error(err);
            if (response.ok) {
              callCallback(null);
              return;
            }

            callCallback(new Error("Unknown error"));
          });
      } else if (
        contentType.toLowerCase() === "text/html" ||
        contentType.toLowerCase() === "text/plain"
      ) {
        response.text().then(
          (result) => {
            if (responseOk) {
              callCallback(null, result);
              return;
            }

            if (result) {
              callCallback(new Error(result));
              return;
            }

            callCallback(new Error("request error, reason unknown"));
          },
          (err) => {
            callCallback(err);
          }
        );
      } else {
        if (headerContentLength > 0) {
          callCallback(unknownContentType);
          return;
        }

        callCallback(null);
      }
    } else {
      if (responseOk) {
        callCallback(null);
        return;
      }

      callCallback(unknownContentType);
    }
  } catch (err) {
    console.error(err);
    callCallback(err);
  }
};

export const getCookie = (name) => {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length === 2) {
    return parts.pop().split(";").shift();
  }
  return "";
};

export const renderModalPrompt = (title = "", props, ChildComponent) =>
  renderModal(Prompt)(title, props, ChildComponent);

export const renderModalComponent = (
  title = "",
  props,
  ChildComponent,
  noModal = false
) => renderModal(Modal)(title, props, ChildComponent, noModal);

const renderModal = (Component) => (
  title = "",
  props,
  ChildComponent,
  noModal
) => {
  const future = q.defer();
  let wrapper = document.body.appendChild(document.createElement("div"));

  if (noModal) {
    ReactDOM.render(<ChildComponent {...props} promise={future} />, wrapper);
  } else {
    ReactDOM.render(
      <Component title={title} promise={future} contentProps={props}>
        <ChildComponent {...props} />
      </Component>,
      wrapper
    );
  }

  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(wrapper);
    setTimeout(() => wrapper.remove());
  };

  future.promise.fin(cleanup);
};

export const getSuffix = (name) => {
  return /[.]/.exec(name) ? /[^.]+$/.exec(name) : undefined;
};

export const getImageURL = (image) => {
  if (image && typeof image === "object") {
    return image.full_path
      ? `https://firebasestorage.googleapis.com/v0/b/${
          image.bucket
        }/o/${encodeURIComponent(image.full_path)}?alt=media`
      : image.url
      ? image.url
      : "";
  }

  return image || "";
};

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const round = (number, multiplier = 1) => {
  return parseFloat((Math.round(multiplier * number * 100) / 100).toFixed(2));
};

export const isValidURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(str);
};
