export default {
  root: {},
  notchedOutline: {
    borderColor: 'rgba(0,0,0,0.15)'
  },
  input: {
    padding: "8px 12px 7px",
  },
  adornedEnd: {
    paddingRight: 0,
  },
};
